<template>
  <div class="container">
    <h5>{{ $t("navList.account") }}</h5>
    <div class="infoCon">
      <div class="infoItem">
        <q-input readonly v-model="info.nickname" :label="$t('account.wechart')" />
      </div>
      <div class="infoItem">
        <q-input readonly v-model="info.mid" :label="$t('account.invitecode')" />
      </div>
      <div class="infoItem">
        <q-input v-model="info.username" :label="$t('account.name')" maxlength="20" />
      </div>
      <div class="infoItem">
        <q-select :label="$t('account.gender')" v-model="info.sex" option-value="value" option-label="label" emit-value
          map-options :options="options" />
      </div>
      <div class="infoItem">
        <q-input :disable="birthdayDis" readonly v-model="info.birthday" :label="$t('account.birthday')">
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                <q-date minimal v-model="info.birthday" navigation-min-year-month="1950/01"
                  navigation-max-year-month="2050/01">
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup :label="$t('account.close')" color="primary" />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
        <div class="desc" style="display: flex; justify-content: space-between">
          <template v-if="$i18n.locale == 'en'">
            <div>CANNOT BE MODIFIED AFTER BIRTHDAY IS SET</div>
            <div v-if="!birthdayDis">
              <b>CLICK ICON</b>
            </div>
          </template>
          <template v-else>
            <div>
              生日设置后
              <b>不可更改</b>
            </div>
            <div v-if="!birthdayDis"><b>点击</b>图标设置</div>
          </template>
        </div>
      </div>
      <div class="infoItem">
        <q-input disable v-model="info.tel" :label="$t('account.phone')" />
        <template v-if="$i18n.locale == 'en'">
          <div class="desc">
            TO CHANGE YOUR PHONE NUMBER
            <span @click="
              $router.push(
                '/my/phone?phone=' + info.tel + '&email=' + info.email
              )
            ">PLEASE CLICK HERE</span>
          </div>
        </template>
        <template v-else>
          <div class="desc">
            如需更改您的手机号码，
            <span @click="
              $router.push(
                '/my/phone?phone=' + info.tel + '&email=' + info.email
              )
            ">请点击这里</span>
          </div>
        </template>
      </div>
      <div class="infoItem">
        <q-input disable v-model="info.password" :label="$t('account.pwd')" />
        <template v-if="$i18n.locale == 'en'">
          <div class="desc">
            TO CHANGE YOUR PASSWORD
            <span @click="
              $router.push('/my/forget?title=' + $t('login_mod.change_pwd'))
            ">PLEASE CLICK HERE</span>
          </div>
        </template>
        <template v-else>
          <div class="desc">
            如需更改您的密码，
            <span @click="
              $router.push('/my/forget?title=' + $t('login_mod.change_pwd'))
            ">请点击这里</span>
          </div>
        </template>
      </div>
      <div class="infoItem">
        <q-input :disable="info.email != '' ? true : false" v-model="info.email" :label="$t('account.email')"
          @focus="focusEmail" />
        <template v-if="$i18n.locale == 'en'">
          <div class="desc">CANNOT BE MODIFIED AFTER EMAIL ADDRESS IS SET</div>
        </template>
        <template v-else>
          <div class="desc">
            邮箱设置后
            <b>不可更改</b>
          </div>
        </template>
      </div>
    </div>
    <div class="infoBtn">
      <q-btn class="full-width" color="primary" @click="saveInfo" :label="$t('account.save')" />
    </div>
  </div>
</template>

<script>
import { upDatedHead } from '@/common/upDatedHead'
import { getInfoApi, editInfoApi } from '@/api/my'
export default {
  data() {
    return {
      info: '',
      options: [{
        label: this.$t('account.male'),
        value: '男'
      }, {
        label: this.$t('account.female'),
        value: '女'
      }],
      birthdayDis: false
    }
  },
  methods: {
    async getInfo() {
      const res = await getInfoApi()
      this.info = res
      if (res.birthday) {
        this.birthdayDis = true
      }
    },
    async saveInfo() {
      if (this.info.username === '') {
        this.$q.notify(this.$t('login_mod.name_input'))
        return
      }
      const res = await editInfoApi({
        mark: this.info.username,
        sex: this.info.sex,
        birthday: this.info.birthday
      })
      if (res.status === 3) {
        if (this.info.birthday) {
          this.birthdayDis = true
        }
        // 更新userinfo
        upDatedHead()
      }
      this.$q.notify(this.$i18n.locale === 'en' ? res.contenten : res.content)
    },
    focusEmail() {
      this.$router.push('/my/email')
    }
  },
  mounted() {
    this.getInfo()
  }
}
</script>

<style lang="scss" scoped>
h5 {
  text-align: center;
}

.infoCon {
  max-width: 1050px;
  padding: 0 25px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .infoItem {
    position: relative;
    width: 45%;
    margin-bottom: 38px;

    .desc {
      margin-top: 5px;

      span {
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

.infoBtn {
  width: 300px;
  margin: 0 auto;
  margin-top: 78px;
}
</style>
